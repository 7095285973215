(function ($, generic, site) {
  window.lpTag = window.lpTag || {};
  window.lpTag.hooks = window.lpTag.hooks || [];

  const LivepersonConciergeBehaviorV1 = {
    attached: false,
    getCartCookieObject: function () {
      const cookieArray = generic.cookie('FE_USER_CART') ? generic.cookie('FE_USER_CART').split('&') : [];
      const responseObject = {};
      cookieArray.forEach((item) => {
        const splitPair = item.split(':');
        const key = splitPair[0];
        const value = splitPair[1] || false;
        responseObject[key] = value;
      });
      return responseObject;
    },

    cartCookieObject: {},
    renderState: {
      isCustomPathMatch: false,
      isDisabled: false,
      isProactiveDisabled: false,
      publicMsg: '',
      tier3Msg: '',
    },

    // Reduce all Tier < 3 users to public level
    getLoyaltyLevelString: function () {
      const cookie = this.cartCookieObject;
      return cookie && cookie.loyalty_level && cookie.loyalty_level === '3' ? cookie.loyalty_level : 'public';
    },

    getPageCategoryString: function (context) {
      const $body = $('body', context);
      const responseEnum = ['custom', 'home', 'spp', 'mpp'];
      if (this.renderState.isCustomPathMatch) {
        const isPublicAndAvailable = this.getLoyaltyLevelString() === 'public' && this.renderState.publicMsg;
        const isTier3AndAvailable = this.getLoyaltyLevelString() === '3' && this.renderState.tier3Msg;
        const msgAvailable = isPublicAndAvailable || isTier3AndAvailable;
        if (msgAvailable) {
          return responseEnum[0];
        }
      }
      if ($body.hasClass('front')) {
        return responseEnum[1];
      }
      if ($body.hasClass('page-product')) {
        return responseEnum[2];
      }
      if ($('.js-mpp-container', context).length > 0) {
        return responseEnum[3];
      }
      return responseEnum[1]; // return home by default
    },

    getDisplayNameString: function () {
      const cookie = this.cartCookieObject;
      return cookie && cookie.first_name ? cookie.first_name : '';
    },

    setAttributeValues: function ($template) {
      $template.attr('level', this.getLoyaltyLevelString());
      $template.attr('page', this.getPageCategoryString());
    },

    setExpandedView: function ($template, isOpen) {
      $template.attr('aria-expanded', isOpen);
      if (!isOpen) {
        generic.cookie('liveperson_minimized', 1, {
          path: '/',
          expires: null,
        });
      }
    },

    isAlreadyMinimized: function () {
      return generic.cookie('liveperson_minimized') === 1;
    },

    processCustomRules: function (rulesArray) {
      const matchedRuleObject = rulesArray.find(rule => {
        let rulePath = rule.data.path_to_check.textfield;
        if (rulePath === '/') {
          return (location.origin + rulePath) === location.href || location.origin === location.href;
        }
        return location.href.includes(rulePath);
      });
      if (!matchedRuleObject) {
        return;
      }
      this.renderState.isCustomPathMatch = true;
      this.renderState.isDisabled = matchedRuleObject.data.is_disabled.checkbox.value === 1;
      this.renderState.isProactiveDisabled = matchedRuleObject.data.is_proactive_disabled.checkbox.value === 1;
      this.renderState.publicMsg = matchedRuleObject.data.public_msg.textarea.value;
      this.renderState.tier3Msg = matchedRuleObject.data.tier_3_msg.textarea.value;
    },

    attach: function (context) {
      const self = this;
      const $template = $('.js-liveperson-concierge--v1', context);
      this.cartCookieObject = this.getCartCookieObject();

      // Toggle between small and full view
      $template.on('click.minimize', function (event) {
        event.preventDefault();
        self.setExpandedView($template, false);
      });

      if ($template.data('nid')) {
        const nid = $template.data('nid');
        $.ajax({
          url: `/elc_api/node/${nid}`,
          success: function (response) {
            let data = response[nid].template_fields;
            if (!Array.isArray(data)) {
              data = [data];
            }
            self.processCustomRules(data);
            self.render($template);
          },
          error: function () {
            self.render($template);
          }
        });
      } else {
        this.render($template);
      }
    },

    render: function ($template) {
      const self = this;
      if (this.attached) {
        return false;
      }
      this.attached = true;
      if (this.renderState.isDisabled) {
        return false;
      }
      this.setAttributeValues($template);
      if (this.renderState.isCustomPathMatch) {
        const $textBodies = $template.find('.js-liveperson-concierge-body');
        $textBodies.filter('[level="public"]').children().filter('[page="custom"]').html(self.renderState.publicMsg);
        $textBodies.filter('[level="3"]').children().filter('[page="custom"]').html(self.renderState.tier3Msg);
      }
      if (!this.renderState.isProactiveDisabled) {
        this.renderUserWelcome($template);
        if (!this.isAlreadyMinimized()) {
          this.runProactiveInviteDisplayTimer($template);
        }
      }
      $template.removeClass('hidden');
    },

    renderUserWelcome: function ($template) {
      const displayNameString = this.getDisplayNameString();
      const nameTemplateString = $template.find('.js-liveperson-name-template').html();
      const $targetElement = $template.find('.js-liveperson-user-welcome');
      if (displayNameString && nameTemplateString) {
        try {
          const rendered = site.template.render(nameTemplateString, {
            name: displayNameString
          });
          $targetElement.html(rendered);
        } catch (e) {
          console.log(e);
        }
      }
    },

    runProactiveInviteDisplayTimer: function ($template) {
      const startTimeInteger = $template.data('start') ? parseInt($template.data('start')) : 0;
      const displayTimeInteger = $template.data('display') ? parseInt($template.data('display')) : 0;
      // Start proactive invite display based on time
      setTimeout(() => {
        this.setExpandedView($template, true);
        if (displayTimeInteger > 0) {
          setTimeout(() => {
            this.setExpandedView($template, false);
          }, displayTimeInteger);
        }
      }, startTimeInteger);
    }
  };

  function cleanAttributes(node) {
    const $node = $(node);
    const $titleAttributes = $('[title]', $node);
    if ($titleAttributes.length > 0) {
      $.each($titleAttributes, function (index, el) {
        const $element = $(el);
        const className = $element.attr('title');
        $element.closest('.lp_chat_line_wrapper').addClass('elc-content-card ' + className);
      });
    }
    try {
      // This try catch block looks for products with a hexcode, and then hides the hexcode value but not the shadename
      // It also increases the height of the product column in case of long product titles
      const $productImage = $(node).find('.lpc_card__image');
      const $textColumn = $(node).find('> .lp-json-pollock-layout-vertical > .lp-json-pollock-layout-horizontal').first().find('.lpc_card_vertical');
      const $productLineItems = $textColumn.find('> .lpc_card');
      const minOffset = 50;
      if ($productImage.length && $textColumn.height() >= $productImage.height()) {
        const differenceInHeight = $textColumn.height() - $productImage.height();
        const newTopPadding = (differenceInHeight >= minOffset ? differenceInHeight : minOffset); // magic number to give enough space between lines of text
        $productImage.css({
          'padding-top': newTopPadding,
        });
      }
      if ($productLineItems.length === 3) {
        $productLineItems.first().addClass('elc-lp-card-product-shade-info');
      }
    } catch (e) {
      console.log(e);
    }
  }

  function insertMessage() {
    const $module = $('.js-liveperson-concierge--v1', document);
    const $container = $('.lp_main_area .lpc_layout_maximized', document);
    const $messageTemplate = $module.find('.js-liveperson-message-prepend');
    if ($messageTemplate.length === 0) {
      return false;
    }
    $container.prepend($messageTemplate[0].content.cloneNode(true));
  }

  function addLpHooks() {
    if (!window.lpTag.hooks) {
      return false;
    }
    const lpConciergeIsLoaded = {
      isLoaded: false,
      get: function () {
        return this.isLoaded;
      },
      set: function (newSetting) {
        this.isLoaded = newSetting;
      }
    };
    const addLpHookFn = () => {
      window.lpTag.hooks.push({
        name: 'ON_WINDOW_CLOSED',
        callback: function () {
          lpConciergeIsLoaded.set(false);
        }
      });
      window.lpTag.hooks.push({
        name: 'AFTER_GET_LINES',
        callback: function () {
          if (lpConciergeIsLoaded.get()) {
            return false;
          }
          lpConciergeIsLoaded.set(true);
          LivepersonConciergeBehaviorV1.setExpandedView($('.js-liveperson-concierge--v1', document), false);
          insertMessage();
          if (window.lpTag !== 'undefined') {
            window.lpTag.events.bind({
              eventName: 'minimized',
              appName: 'lpUnifiedWindow',
              func: function () {
                lpConciergeIsLoaded.set(false);
              },
            });
          }
          const observer = new MutationObserver(function (mutationsList) {
            $.each(mutationsList, function (key, mutation) {
              $.each(mutation.addedNodes, function (index, addedNode) {
                const strContent = $('.lp-json-pollock', addedNode)[0];
                if (strContent) {
                  cleanAttributes(strContent);
                }
              });
            });
          });
          observer.observe($("[data-lp-point='lines_area']", document)[0], {
            subtree: false,
            childList: true
          });
        }
      });
    };
    addLpHookFn();
  }
  addLpHooks();
  Drupal.behaviors.livepersonConciergeV1 = LivepersonConciergeBehaviorV1;
})(jQuery, window.generic || {}, window.site || {});
